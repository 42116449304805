import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import {
  Modal,
  InlineTextInput,
  InlineSelect,
  InlineSwitch,
  InlineTextArea,
  BasicButton,
  SubmitButton,
} from '../../../components/Base';
import Collapsible from '../../../components/Collapsible';
import CkEditor from '../../../components/CkEditor';
import { APP_URL } from '../../../constants/env';
import Utils from '../../../helpers/utils';

import { fetchProductCategory } from '../../../actions/productCategory';
import { submitProduct, updateProduct } from '../../../actions/product';

function ProductCreate(props, ref) {
  const { register, errors, handleSubmit, setValue, setError, clearErrors } = useForm();

  const [isVisible, setIsVisible] = useState(false);
  const [initialProduct, setInitialProduct] = useState(null);
  const [productVariants, setProductVariants] = useState([]);
  const [editorContent, setEditorContent] = useState('');

  const [originalPrice, setOriginalPrice] = useState(0);
  const [discountType, setDiscountType] = useState('');
  const [discountAmount, setDiscountAmount] = useState(0);
  const [price, setPrice] = useState(0);

  const [image1, setImage1] = useState('');
  const [image2, setImage2] = useState('');
  const [image3, setImage3] = useState('');
  const [image4, setImage4] = useState('');
  const [image5, setImage5] = useState('');
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const { result: productCategories } = useSelector((state) => state.productCategory);
  const dispatch = useDispatch();

  //

  useEffect(() => {
    dispatch(fetchProductCategory());
  }, []);

  useEffect(() => {
    if (initialProduct) {
      setTimeout(() => {
        setValue('sku', initialProduct.sku);
        setValue('name', initialProduct.name);
        setValue('product_category_id', initialProduct.product_category_id);
        setValue('status', initialProduct.status === 1);
        setValue('is_new', initialProduct.is_new === 1);
        setValue('is_featured', initialProduct.is_featured === 1);

        setProductVariants(
          initialProduct.product_variants.map((variant) => ({
            ...variant,
            is_deleted: 0,
          })),
        );

        setEditorContent(_.unescape(initialProduct.description));
        setValue('original_price', initialProduct.original_price);
        setValue('discount_type', initialProduct.discount_type);
        setValue('discount_amount', initialProduct.discount_amount);

        setOriginalPrice(initialProduct.original_price);
        setDiscountType(initialProduct.discount_type);
        setDiscountAmount(initialProduct.discount_amount);

        setValue('width', initialProduct.width);
        setValue('height', initialProduct.height);
        setValue('depth', initialProduct.depth);
        setValue('weight', initialProduct.weight);
        if (initialProduct.image_1) setImage1(`${APP_URL}/${initialProduct.image_1}`);
        if (initialProduct.image_2) setImage1(`${APP_URL}/${initialProduct.image_2}`);
        if (initialProduct.image_3) setImage1(`${APP_URL}/${initialProduct.image_3}`);
        if (initialProduct.image_4) setImage1(`${APP_URL}/${initialProduct.image_4}`);
        if (initialProduct.image_5) setImage1(`${APP_URL}/${initialProduct.image_5}`);

        setValue('url_slug', initialProduct.url_slug);
        setValue('meta_title', initialProduct.meta_title);
        setValue('meta_description', initialProduct.meta_description);
        setValue('meta_keywords', initialProduct.meta_keywords);
      }, 100);
    }
  }, [initialProduct]);

  // Calculate Final Price
  useEffect(() => {
    const discount =
      discountType === 'Percentage' ? (discountAmount * originalPrice) / 100 : discountAmount;
    setPrice(originalPrice - discount);
  }, [originalPrice, discountType, discountAmount]);

  //

  useImperativeHandle(ref, () => ({
    showForm,
    showFormWithInitialData,
  }));

  const showForm = () => setIsVisible(true);
  const closeForm = () => {
    setIsVisible(false);
    setInitialProduct(null);
    setProductVariants([]);
    setEditorContent('');
    setImage1('');
    setImage2('');
    setImage3('');
    setImage4('');
    setImage5('');
    setIsSubmittingForm(false);
  };

  const showFormWithInitialData = (data) => {
    setInitialProduct(data);
    showForm();
  };

  const generateUrlSlug = (e) => {
    const slug = Utils.stringToSlug(e.target.value);
    setValue('url_slug', slug);
  };

  //

  const onPressAddVariant = () => {
    const variants = [...productVariants];
    variants.push({
      id: 0,
      is_deleted: 0,
      sku: '',
      name: '',
    });
    setProductVariants(variants);
  };

  const onPressDeleteVariant = (index) => {
    const variants = [...productVariants];
    if (variants[index].id === 0) {
      variants.splice(index, 1);
    } else {
      variants[index].is_deleted = 1;
    }
    setProductVariants(variants);
  };

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const uploadedFile = e.target.files[0];
      const inputName = e.target.name;

      if (uploadedFile.type.indexOf('image') === -1) {
        setError(inputName, {
          type: 'manual',
          message: 'Image must be an image (jpg, png, gif, svg, or webp)',
        });
        return;
      }

      if (uploadedFile.size > 1000000) {
        setError(inputName, {
          type: 'manual',
          message: "Image size can't be larger than 1 MB",
        });
        return;
      }

      clearErrors(inputName);
      switch (inputName) {
        case 'image_2':
          setImage2(URL.createObjectURL(e.target.files[0]));
          break;
        case 'image_3':
          setImage3(URL.createObjectURL(e.target.files[0]));
          break;
        case 'image_4':
          setImage4(URL.createObjectURL(e.target.files[0]));
          break;
        case 'image_5':
          setImage5(URL.createObjectURL(e.target.files[0]));
          break;
        case 'image_1':
        default:
          setImage1(URL.createObjectURL(e.target.files[0]));
          break;
      }
    }
  };

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    const formData = new FormData();
    formData.append('sku', data.sku);
    formData.append('name', data.name);
    formData.append('product_category_id', data.product_category_id);
    formData.append('status', data.status ? 1 : 0);
    formData.append('is_new', data.is_new ? 1 : 0);
    formData.append('is_featured', data.is_featured ? 1 : 0);

    productVariants.forEach((variant, index) => {
      formData.append(`product_variants[${index}][id]`, variant.id);
      formData.append(`product_variants[${index}][is_deleted]`, variant.is_deleted);
      formData.append(`product_variants[${index}][sku]`, variant.sku);
      formData.append(`product_variants[${index}][name]`, variant.name);
    });

    formData.append('description', editorContent);
    formData.append('original_price', data.original_price);
    formData.append('discount_type', data.discount_type);
    formData.append('discount_amount', data.discount_amount);
    formData.append('width', data.width);
    formData.append('height', data.height);
    formData.append('depth', data.depth);
    formData.append('weight', data.weight);
    if (data.image_1.length > 0) formData.append('image_1', data.image_1[0]);
    if (data.image_2.length > 0) formData.append('image_2', data.image_2[0]);
    if (data.image_3.length > 0) formData.append('image_3', data.image_3[0]);
    if (data.image_4.length > 0) formData.append('image_4', data.image_4[0]);
    if (data.image_5.length > 0) formData.append('image_5', data.image_5[0]);

    formData.append('url_slug', data.url_slug);
    formData.append('meta_title', data.meta_title);
    formData.append('meta_description', data.meta_description);
    formData.append('meta_keywords', data.meta_keywords);

    if (initialProduct) {
      dispatch(updateProduct(initialProduct.id, formData))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    } else {
      dispatch(submitProduct(formData))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    }
  };

  return (
    <Modal
      isVisible={isVisible}
      size="large"
      ModalContent={
        <div className="sm:flex sm:items-start">
          <div className="w-full mt-3 text-center sm:mt-0 sm:text-left">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              {initialProduct ? 'Ubah Kategori Produk' : 'Tambah Kategori Produk Baru'}
            </Dialog.Title>
            <div className="mt-5">
              <form id="createForm" onSubmit={handleSubmit(onSubmitForm)}>
                <Collapsible title="General">
                  <InlineTextInput
                    type="text"
                    title="SKU*"
                    inputRef={register({ required: true })}
                    name="sku"
                    errorMessage={errors.sku && 'Kolom SKU harus diisi'}
                  />

                  <InlineTextInput
                    type="text"
                    title="Nama Produk*"
                    inputRef={register({ required: true })}
                    name="name"
                    errorMessage={errors.name && 'Kolom Nama Produk harus diisi'}
                    maxLength="200"
                    onBlur={generateUrlSlug}
                  />

                  <InlineSelect
                    title="Kategori Produk*"
                    inputRef={register({ required: true })}
                    name="product_category_id"
                    options={productCategories.map((c) => [
                      <option key={c.id} value={c.id}>
                        {c.name}
                      </option>,
                      c.sub_categories.map((s) => (
                        <option key={`${c.id}.${s.id}`} value={s.id}>
                          &emsp;&emsp;{`- ${s.name}`}
                        </option>
                      )),
                    ])}
                    errorMessage={
                      errors.product_category_id && 'Kolom Kategori Produk harus dipilih'
                    }
                  />

                  <InlineSwitch
                    title="Status"
                    inputRef={register()}
                    name="status"
                    defaultChecked={true}
                  />

                  <InlineSwitch title="New" inputRef={register()} name="is_new" />

                  <InlineSwitch title="Featured" inputRef={register()} name="is_featured" />
                </Collapsible>

                <Collapsible title="Variasi Produk">
                  {productVariants.map((variant, index) => (
                    <div key={index} className="relative w-full mb-5">
                      <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                        <div>
                          <label className="block uppercase text-gray-700 text-xs font-bold">
                            {`Variasi Produk ${index + 1}`}
                          </label>
                        </div>

                        <div className="col-span-2 xl:col-span-3">
                          <div className="grid grid-cols-1 md:grid-cols-5 gap-1 md:gap-4 items-center">
                            <div className="col-span-1 md:col-span-2">
                              <input
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                ref={register({ required: true })}
                                type="text"
                                placeholder="SKU*"
                                name={`product_variant_sku[${index}]`}
                                defaultValue={productVariants[index].sku}
                                onBlur={(e) => {
                                  productVariants[index].sku = e.target.value;
                                  setProductVariants(productVariants);
                                }}
                              />
                              {errors.product_variant_sku && errors.product_variant_sku[index] && (
                                <span className="text-xs text-red-500">Kolom SKU harus diisi</span>
                              )}
                            </div>
                            <div className="col-span-1 md:col-span-2">
                              <input
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                ref={register({ required: true })}
                                type="text"
                                placeholder="Nama Variasi*"
                                name={`product_variant_name[${index}]`}
                                defaultValue={productVariants[index].name}
                                onBlur={(e) => {
                                  productVariants[index].name = e.target.value;
                                  setProductVariants(productVariants);
                                }}
                              />
                              {errors.product_variant_name &&
                                errors.product_variant_name[index] && (
                                  <span className="text-xs text-red-500">
                                    Kolom Nama harus diisi
                                  </span>
                                )}
                            </div>
                            <div className="col-span-1 md:col-span-1">
                              <SubmitButton
                                type="button"
                                text="X"
                                color="red"
                                textClass="text-white text-xs"
                                onClick={() => onPressDeleteVariant(index)}
                              />
                            </div>
                          </div>

                          {variant.is_deleted === 1 && (
                            <span className="text-xs text-red-500">
                              Variasi Produk akan dihapus pada saat Submit
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}

                  <SubmitButton
                    type="button"
                    text="Tambah Variasi Produk"
                    textClass="text-white text-xs"
                    onClick={onPressAddVariant}
                  />
                </Collapsible>

                <Collapsible title="Description">
                  <label className="mb-2 block uppercase text-gray-700 text-xs font-bold">
                    Deskripsi Produk*
                  </label>
                  <CkEditor
                    data={editorContent}
                    onBlur={(event, editor) => {
                      const data = editor.getData();
                      setEditorContent(data);
                    }}
                  />
                </Collapsible>

                <Collapsible title="Price">
                  <InlineTextInput
                    inputWidth="w-1/2"
                    type="number"
                    title="Harga (Rp)*"
                    inputRef={register({ required: true })}
                    name="original_price"
                    value={`${originalPrice}`}
                    onChange={(e) => setOriginalPrice(Number(e.target.value))}
                    errorMessage={errors.price && 'Kolom Harga harus diisi'}
                  />

                  <InlineSelect
                    inputWidth="w-1/2"
                    title="Tipe Discount*"
                    inputRef={register()}
                    name="discount_type"
                    options={[
                      <option key={0} value="Fixed Amount">
                        Jumlah Tetap (Rp)
                      </option>,
                      <option key={1} value="Percentage">
                        Persentase (%)
                      </option>,
                    ]}
                    value={discountType}
                    onChange={(e) => setDiscountType(e.target.value)}
                  />

                  <InlineTextInput
                    inputWidth="w-1/2"
                    type="number"
                    title="Jumlah Discount*"
                    inputRef={register()}
                    name="discount_amount"
                    value={`${discountAmount}`}
                    onChange={(e) => setDiscountAmount(Number(e.target.value))}
                  />

                  <InlineTextInput
                    readOnly
                    inputWidth="w-1/2"
                    type="number"
                    title="Harga Akhir (Rp)"
                    value={`${price}`}
                  />
                </Collapsible>

                <Collapsible title="Shipping">
                  <InlineTextInput
                    inputWidth="w-1/2"
                    type="number"
                    title="Panjang (cm)"
                    inputRef={register()}
                    name="width"
                    defaultValue={0}
                    errorMessage={errors.width && 'Kolom Panjang harus diisi'}
                  />

                  <InlineTextInput
                    inputWidth="w-1/2"
                    type="number"
                    title="Lebar (cm)"
                    inputRef={register()}
                    name="depth"
                    defaultValue={0}
                    errorMessage={errors.depth && 'Kolom Lebar harus diisi'}
                  />

                  <InlineTextInput
                    inputWidth="w-1/2"
                    type="number"
                    title="Tinggi (cm)"
                    inputRef={register()}
                    name="height"
                    defaultValue={0}
                    errorMessage={errors.height && 'Kolom Tinggi harus diisi'}
                  />

                  <InlineTextInput
                    inputWidth="w-1/2"
                    type="number"
                    title="Berat (g)*"
                    inputRef={register({ required: true })}
                    name="weight"
                    errorMessage={errors.weight && 'Kolom Berat harus diisi'}
                  />
                </Collapsible>

                <Collapsible title="Image">
                  <div>
                    <img src={image1} alt="" className="w-32 mb-2" />
                    <InlineTextInput
                      type="file"
                      accept="image/*"
                      title="Image 01*"
                      inputRef={register({ required: initialProduct ? false : true })}
                      name="image_1"
                      onChange={onImageChange}
                      errorMessage={
                        errors.image_1 &&
                        (errors.image_1.type === 'manual'
                          ? errors.image_1.message
                          : 'Kolom Image harus diisi')
                      }
                    />
                    <div className="-mt-3 mb-4 text-right text-xs">Image size max 1 MB &emsp;</div>
                  </div>

                  <div>
                    <img src={image2} alt="" className="w-32 mb-2" />
                    <InlineTextInput
                      type="file"
                      accept="image/*"
                      title="Image 02"
                      inputRef={register()}
                      name="image_2"
                      onChange={onImageChange}
                      errorMessage={errors.image_2 && errors.image_2.message}
                    />
                    <div className="-mt-3 mb-4 text-right text-xs">Image size max 1 MB &emsp;</div>
                  </div>

                  <div>
                    <img src={image3} alt="" className="w-32 mb-2" />
                    <InlineTextInput
                      type="file"
                      accept="image/*"
                      title="Image 03"
                      inputRef={register()}
                      name="image_3"
                      onChange={onImageChange}
                      errorMessage={errors.image_3 && errors.image_3.message}
                    />
                    <div className="-mt-3 mb-4 text-right text-xs">Image size max 1 MB &emsp;</div>
                  </div>

                  <div>
                    <img src={image4} alt="" className="w-32 mb-2" />
                    <InlineTextInput
                      type="file"
                      accept="image/*"
                      title="Image 04"
                      inputRef={register()}
                      name="image_4"
                      onChange={onImageChange}
                      errorMessage={errors.image_4 && errors.image_4.message}
                    />
                    <div className="-mt-3 mb-4 text-right text-xs">Image size max 1 MB &emsp;</div>
                  </div>

                  <div>
                    <img src={image5} alt="" className="w-32 mb-2" />
                    <InlineTextInput
                      type="file"
                      accept="image/*"
                      title="Image 05"
                      inputRef={register()}
                      name="image_5"
                      onChange={onImageChange}
                      errorMessage={errors.image_5 && errors.image_5.message}
                    />
                    <div className="-mt-3 mb-4 text-right text-xs">Image size max 1 MB &emsp;</div>
                  </div>
                </Collapsible>

                <Collapsible title="SEO">
                  <InlineTextInput
                    type="text"
                    title="URL Slug*"
                    inputRef={register({ required: true })}
                    name="url_slug"
                    errorMessage={errors.url_slug && 'Kolom URL Slug harus diisi'}
                  />

                  <InlineTextInput
                    type="text"
                    title="Meta Title (max 50 chars)"
                    inputRef={register()}
                    name="meta_title"
                    errorMessage={errors.meta_title && 'Kolom Meta Title harus diisi'}
                    maxLength="50"
                  />

                  <InlineTextArea
                    title="Meta Description (max 150 chars)"
                    inputRef={register()}
                    name="meta_description"
                    errorMessage={errors.meta_description && 'Kolom Meta Description harus diisi'}
                    maxLength="150"
                  />

                  <InlineTextInput
                    type="text"
                    title="Meta Keywords"
                    inputRef={register()}
                    name="meta_keywords"
                    errorMessage={errors.meta_keywords && 'Kolom Meta Keywords harus diisi'}
                    maxLength="250"
                  />
                </Collapsible>
              </form>
            </div>
          </div>
        </div>
      }
      ModalButton={
        <>
          <SubmitButton
            type="submit"
            form="createForm"
            text="Submit"
            textClass="text-white text-xs"
            isLoading={isSubmittingForm}
          />
          <BasicButton text="Cancel" textClass="text-white text-xs mr-2" onClick={closeForm} />
        </>
      }
    />
  );
}

export default forwardRef(ProductCreate);
