export const APP_ENV = 'dev';
export const APP_NAME = 'BSTORE';
export const APP_KEY = 'BSTORE-BACKOFFICE';
const APP_URL_ENV = {
  development: 'http://localhost/bstore-api/public',
  production: 'https://api.backoffice.bstore.co.id',
};
export const APP_URL = APP_URL_ENV[process.env.NODE_ENV];

/* API CREDENTIALS */
const API_URL_ENV = {
  development: 'http://localhost/bstore-api/public/api/backoffice',
  production: 'https://api.backoffice.bstore.co.id/api/backoffice',
};
export const API_URL = API_URL_ENV[process.env.NODE_ENV];

export const API_HEADER = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  dataType: 'json',
  'X-Requested-With': 'XMLHttpRequest',
};
export const API_HEADER_MULTIPART = {
  Accept: 'application/json',
  // 'Content-Type': 'multipart/form-data',
  dataType: 'json',
  'X-Requested-With': 'XMLHttpRequest',
};

export const API_RESPONSE_STATUS = 'status';
export const API_RESPONSE_MESSAGE = 'message';
export const API_RESPONSE = {
  SUCCESS: 'success',
  FAILED: 'failed',
};

/* PAYMENT GATEWAY */
const PAYMENT_GATEWAY_URL_ENV = {
  development: 'https://sandbox.ipay88.co.id/PG/',
  production: 'https://payment.ipay88.co.id/PG/',
};
export const PAYMENT_GATEWAY_URL = PAYMENT_GATEWAY_URL_ENV[process.env.NODE_ENV];

/* LOCAL STORAGE KEY */
export const AUTHENTICATED_USER_STORAGE_KEY = `@${APP_KEY}:authenticatedUser`;
export const CART_STORAGE_KEY = `@${APP_KEY}:cart`;

/* STATICS VARIABLES */
export const DATATABLES_DEFAULT_CONFIG = {
  processing: true,
  serverSide: true,
  deferRender: true,
  dom: `<''
    <'sm:flex justify-center items-center'
      <'sm:flex-1 text-center sm:text-left'l>
      <'sm:flex-1 text-center sm:text-right'f>
    >
    <'dt-table mt-3'
      <''tr>
    >
    <'sm:flex mt-3 justify-center'
      <'sm:flex-1 text-center sm:text-left'i>
      <'sm:flex-1 text-center sm:text-right'p>
    >
  >`,
};
