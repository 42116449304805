import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import {
  Modal,
  InlineTextInput,
  InlineTextArea,
  InlineSwitch,
  BasicButton,
  SubmitButton,
} from '../../../components/Base';
import Collapsible from '../../../components/Collapsible';
import CkEditor from '../../../components/CkEditor';
import Utils from '../../../helpers/utils';
import { submitPage, updatePage } from '../../../actions/page';

function PageCreate(props, ref) {
  const { register, errors, handleSubmit, setValue } = useForm();

  const [isVisible, setIsVisible] = useState(false);
  const [initialPage, setInitialPage] = useState(null);
  const [editorContent, setEditorContent] = useState('');
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    showForm,
    showFormWithInitialData,
  }));

  useEffect(() => {
    if (initialPage) {
      setTimeout(() => {
        setValue('title', initialPage.title);
        setValue('url_slug', initialPage.url_slug);
        setValue('meta_title', initialPage.meta_title);
        setValue('meta_description', initialPage.meta_description);
        setValue('meta_keywords', initialPage.meta_keywords);
        setValue('show_in_footer', initialPage.show_in_footer === 1);
        setEditorContent(_.unescape(initialPage.content));
      }, 100);
    }
  }, [initialPage]);

  const showForm = () => setIsVisible(true);
  const closeForm = () => {
    setIsVisible(false);
    setInitialPage(null);
    setEditorContent('');
    setIsSubmittingForm(false);
  };

  const showFormWithInitialData = (data) => {
    setInitialPage(data);
    showForm();
  };

  const generateUrlSlug = (e) => {
    const slug = Utils.stringToSlug(e.target.value);
    setValue('url_slug', slug);
  };

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    data.content = editorContent;
    if (initialPage) {
      dispatch(updatePage(initialPage.id, data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    } else {
      dispatch(submitPage(data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    }
  };

  return (
    <Modal
      isVisible={isVisible}
      size="large"
      ModalContent={
        <div className="sm:flex sm:items-start">
          <div className="w-full mt-3 text-center sm:mt-0 sm:text-left">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              {initialPage ? 'Ubah Page' : 'Tambah Page Baru'}
            </Dialog.Title>
            <div className="mt-5">
              <form id="createForm" onSubmit={handleSubmit(onSubmitForm)}>
                <Collapsible title="General">
                  <InlineTextInput
                    type="text"
                    title="Judul Halaman"
                    inputRef={register({ required: true })}
                    name="title"
                    errorMessage={errors.title && 'Kolom Judul Halaman harus diisi'}
                    maxLength="200"
                    onBlur={generateUrlSlug}
                  />

                  <div>
                    <label className="mb-2 block uppercase text-gray-700 text-xs font-bold">
                      Page Content
                    </label>
                    <CkEditor
                      // editor={ClassicEditor}
                      // config={{
                      // extraPlugins: [Base64UploadAdapter],
                      // }}
                      data={editorContent}
                      onBlur={(event, editor) => {
                        const data = editor.getData();
                        setEditorContent(data);
                      }}
                    />
                  </div>

                  <div className="mt-5">
                    <InlineSwitch
                      title="Tampilkan di Footer"
                      inputRef={register()}
                      name="show_in_footer"
                      defaultChecked={true}
                    />
                  </div>
                </Collapsible>

                <Collapsible title="SEO">
                  <InlineTextInput
                    type="text"
                    title="URL Slug"
                    inputRef={register({ required: true })}
                    name="url_slug"
                    errorMessage={errors.url_slug && 'Kolom URL Slug harus diisi'}
                  />

                  <InlineTextInput
                    type="text"
                    title="Meta Title (max 50 chars)"
                    inputRef={register()}
                    name="meta_title"
                    errorMessage={errors.meta_title && 'Kolom Meta Title harus diisi'}
                    maxLength="50"
                  />

                  <InlineTextArea
                    title="Meta Description (max 150 chars)"
                    inputRef={register()}
                    name="meta_description"
                    errorMessage={errors.meta_description && 'Kolom Meta Description harus diisi'}
                    maxLength="150"
                  />

                  <InlineTextInput
                    type="text"
                    title="Meta Keywords"
                    inputRef={register()}
                    name="meta_keywords"
                    errorMessage={errors.meta_keywords && 'Kolom Meta Keywords harus diisi'}
                    maxLength="250"
                  />
                </Collapsible>
              </form>
            </div>
          </div>
        </div>
      }
      ModalButton={
        <>
          <SubmitButton
            type="submit"
            form="createForm"
            text="Submit"
            textClass="text-white text-xs"
            isLoading={isSubmittingForm}
          />
          <BasicButton text="Cancel" textClass="text-white text-xs mr-2" onClick={closeForm} />
        </>
      }
    />
  );
}

export default forwardRef(PageCreate);
